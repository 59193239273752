/* eslint-disable */
<template>
  <div class="wrapper">
    <DxChart
      :data-source="store.stats"
      palette="Violet"
      title="Динамика количества попыток пересечения границы по дням"
      style="width: 100%"
    >
      <DxCommonSeriesSettings argument-field="message_dt" type="spline">
        <DxPoint :visible="false" />
      </DxCommonSeriesSettings>
      <DxCommonAxisSettings>
        <DxGrid :visible="true" />
      </DxCommonAxisSettings>
      <DxSeries
        v-for="architecture in architectures"
        :key="architecture.value"
        :value-field="architecture.value"
        :name="architecture.name"
      />
      <DxMargin :bottom="20" />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
      </DxArgumentAxis>
      <DxScrollBar :visible="true" />
      <DxZoomAndPan argument-axis="both" />
      <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
      <DxTooltip :enabled="true" />
    </DxChart>
    <!--  -->
    <DxChart
      id="chart"
      :data-source="store.airports"
      :title="titleAirport"
      class="chart"
    >
      <DxCommonSeriesSettings argument-field="iata_code" type="stackedbar" />
      <DxSeries value-field="success_cnt" name="Успешно" />
      <DxSeries value-field="failed_cnt" name="Не прошли" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="top"
      />
      <DxTooltip
        :enabled="true"
        :customize-tooltip="airportTooltip"
        location="edge"
      />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
      </DxArgumentAxis>
    </DxChart>
    <!--  -->
    <DxChart
      id="chart"
      :data-source="store.airportsAllTime"
      title="Статистика аэропортов за все время"
      class="chart"
    >
      <DxCommonSeriesSettings argument-field="iata_code" type="stackedbar" />
      <DxSeries value-field="success_cnt" name="Успешно" color="#04a863" />
      <DxSeries value-field="failed_cnt" name="Не прошли" color="#ee1c25" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="top"
      />
      <DxTooltip
        :enabled="true"
        :customize-tooltip="airportTooltip"
        location="edge"
      />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
      </DxArgumentAxis>
    </DxChart>
    <!-- destination -->
    <DxChart
      id="chart"
      :data-source="store.destinationsToday"
      :title="titleCountry"
      class="chart"
    >
      <DxCommonSeriesSettings argument-field="destination" type="stackedbar" />
      <DxSeries value-field="success_cnt" name="Успешно" color="#233291" />
      <DxSeries value-field="failed_cnt" name="Не прошли" color="#ee1c25" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="top"
      />
      <DxTooltip
        :enabled="true"
        :customize-tooltip="destinationTooltip"
        location="edge"
      />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
      </DxArgumentAxis>
    </DxChart>
    <!--  -->
    <DxChart
      id="chart"
      :data-source="store.destinationsAllTime"
      title="Статистика по странам прибытия за все время"
      class="chart"
    >
      <DxCommonSeriesSettings argument-field="destination" type="stackedbar" />
      <DxSeries value-field="success_cnt" name="Успешно" color="#315e22" />
      <DxSeries value-field="failed_cnt" name="Не прошли" color="#ee1c25" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="top"
      />
      <DxTooltip
        :enabled="true"
        :customize-tooltip="destinationTooltip"
        location="edge"
      />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
      </DxArgumentAxis>
    </DxChart>
  </div>
</template>
<script>
import store from "../store/store";
import { DxLegend, DxSeries, DxTooltip } from "devextreme-vue/pie-chart";

import {
  DxChart,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxMargin,
  DxPoint,
  DxScrollBar,
  DxZoomAndPan,
  DxLabel,
} from "devextreme-vue/chart";

export default {
  components: {
    DxLegend,
    DxSeries,
    DxTooltip,
    DxLabel,
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxMargin,
    DxPoint,
    DxScrollBar,
    DxZoomAndPan,
  },
  data() {
    return {
      store,
      angle: 45,
      architectures: [
        { value: "cnt", name: "Всего попыток" },
        { value: "success_cnt", name: "Успешно" },
        { value: "failed_cnt", name: "Не пропустили" },
      ],
    };
  },
  computed: {
    titleAirport() {
      return `Статистика аэропортов за ${store.dateFormatter(store.date)}`;
    },
    titleCountry() {
      return `Статистика по странам прибытия за ${store.dateFormatter(
        store.date
      )}`;
    },
  },
  methods: {
    destinationTooltip(pointInfo) {
      return {
        text: `${pointInfo.point.data.destination}<br>${
          pointInfo.seriesName
        }: ${pointInfo.valueText}<br>${(pointInfo.percent * 100).toFixed(2)}%`,
      };
    },
    airportTooltip(pointInfo) {
      return {
        text: `${pointInfo.point.data.airport_name}<br>${
          pointInfo.seriesName
        }: ${pointInfo.valueText}<br>${(pointInfo.percent * 100).toFixed(2)}%`,
      };
    },
    customizeTooltip({ percent }) {
      return {
        text: `${(percent * 100).toFixed(2)}%`,
      };
    },
    customizeTooltip2(pointInfo) {
      return {
        text: `${pointInfo.argumentText}<br>${pointInfo.seriesName}: ${pointInfo.valueText}`,
      };
    },
    customizeTooltip3(pointInfo) {
      return {
        text: `${pointInfo.argumentText}<br>${pointInfo.seriesName}: ${
          pointInfo.valueText
        }<br>${(pointInfo.percent * 100).toFixed(2)}%`,
      };
    },
  },
};
</script>
<style>
.wrapper {
  display: flex;
  align-items: flex-start;
  margin: 2% 0;
  flex-wrap: wrap;
}
.chart {
  width: 50%;
}
@media screen and (max-width: 1000px) {
  .chart {
    width: 100%;
  }
}
</style>
